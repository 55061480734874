import React from 'react'
import Footer from './Footer';
import Navbar from './Navbar';

const About = () => {


    return (

        <div>
            <Navbar />
            <Footer />
        </div>
    )
}

export default About